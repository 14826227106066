import { createContext } from 'react'
import type { SanityClient } from '@sanity/client'
import sanityClient from '@sanity/client'

const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true, // `false` if you want to ensure fresh data
})

const SanityContext = createContext<SanityClient | undefined>(undefined)

export const SanityProvider = ({ children }: React.PropsWithChildren) => {
  return <SanityContext.Provider value={client}>{children}</SanityContext.Provider>
}

export default SanityContext
